import { findRoute } from './findRoute';
import { getRouteMatch } from './getRouteMatch';
import { parseQuery } from './parseQuery';
import { treeifyRoutes } from './treeifyRoutes';
import type { Query, InvariantRoute, Match } from './types';

export function matchRoute<T extends InvariantRoute>(
	routes: T[],
	pathname: string,
	queryParams: string | Query = {},
	basePath = '',
): { route: T; match: Match } | null {
	const queryParamObject = typeof queryParams === 'string' ? parseQuery(queryParams) : queryParams;

	// fast return if there is no route or only one route
	if (routes.length === 0) {
		return null;
	}

	if (routes.length === 1) {
		return getRouteMatch(routes[0], pathname, queryParamObject, basePath);
	}

	const tree = treeifyRoutes(routes);
	const route = findRoute(tree, pathname, queryParamObject, basePath) || tree.fallbackRoute;
	return getRouteMatch(route, pathname, queryParamObject, basePath);
}
