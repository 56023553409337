/* eslint-disable max-classes-per-file */

import type { InvariantRoute } from './types';

export class Node<T extends InvariantRoute> {
	children: { [key: string]: Node<T> };

	// routes that match this node
	// this is an array because multiple routes can match the same path, but have different query params
	routes: T[];

	// the level of the node in the tree, starting from 0
	level: number;

	// the segment pattern of the node e.g. '/:id'
	segmentPattern: string;

	constructor(level: number, segmentPattern: string) {
		this.children = {};
		this.routes = [];
		this.level = level;
		this.segmentPattern = segmentPattern;
	}
}

export class Tree<T extends InvariantRoute> {
	root: Node<T>;

	// fallback route is the routes that match everything.
	// this is normally useful for 404 pages
	// we specifically handle this route for performance reason
	// because if we put it into the tree, it will always get traversed and has the least specificity.
	fallbackRoute: T | null = null;

	constructor() {
		this.root = new Node(0, '');
	}

	insert(segmentPatterns: Array<string>, route: T) {
		let current = this.root;
		for (let i = 0; i < segmentPatterns.length; i++) {
			const segmentPattern = segmentPatterns[i];
			if (!current.children[segmentPattern]) {
				current.children[segmentPattern] = new Node(i + 1, segmentPattern);
			}
			current = current.children[segmentPattern];
		}
		current.routes.push(route);
	}
}

const trim = (segmentPatterns: Array<string>) => {
	// remove the first empty string
	if (segmentPatterns[0] === '') {
		segmentPatterns.shift();
	}
	// remove the last empty string
	if (segmentPatterns[segmentPatterns.length - 1] === '') {
		segmentPatterns.pop();
	}
};

export function treeifyRoutes<T extends InvariantRoute>(routes: T[]) {
	const tree = new Tree<T>();

	routes.forEach((route) => {
		if (
			typeof route.path !== 'string' ||
			route.path === '' ||
			route.path === '/*' ||
			route.path === '*'
		) {
			if (tree.fallbackRoute) {
				throw new Error('There should be only one route that mates everything.');
			}
			tree.fallbackRoute = route;
			return;
		}

		// the regex will match ":projectType(software|software/c|servicedesk|core)"
		// it handles the case where we have multiple segments in a capture group such as "software/c"
		// the regex can be improved to handle more general cases, however let's keep it simple for now
		const match = /:projectType\(([a-z|]*software\/c[a-z|]*)\)/.exec(route.path);
		if (match) {
			// `match` looks like [":projectType(software|software/c|servicedesk|core)", "software|software/c|servicedesk|core" ...]
			const segment = match[0];
			const captureGroup = match[1];
			captureGroup.split('|').forEach((projectType) => {
				// we craete several branches, e.g.
				// /jira/:projectType(software|software/c)/foo
				// becauses
				// - /jira/software/foo
				// - /jira/software/c/foo
				// although they appear in different position in the tree, they should share the same route
				const path = route.path.replace(segment, projectType);
				const segmentPatterns = path.split('/');
				trim(segmentPatterns);
				tree.insert(segmentPatterns, route);
			});
		} else {
			const segmentPatterns = route.path.split('/');
			trim(segmentPatterns);
			tree.insert(segmentPatterns, route);
		}
	});

	return tree;
}
