// Source: https://github.com/unshiftio/querystringify/blob/2.2.0/index.js
// Purpose: Eliminate dependencies from `@atlassian/match-route` for micro-optimization

import type { Query } from './types';

/**
 * Decode a URI encoded string.
 *
 * @param {String} input The URI encoded string.
 * @returns {String|Null} The decoded string.
 * @api private
 */
function decode(input: string): string | null {
	try {
		return decodeURIComponent(input.replace(/\+/g, ' '));
	} catch (e) {
		return null;
	}
}

/**
 * Simple query string parser.
 *
 * @param {String} query The query string that needs to be parsed.
 * @returns {Object}
 * @api public
 */
export function parseQuery(query: string): Query {
	const parser = /([^=?#&]+)=?([^&]*)/g;
	const result: Query = {};
	let part;

	while ((part = parser.exec(query))) {
		var key = decode(part[1]),
			value = decode(part[2]);

		//
		// Prevent overriding of existing properties. This ensures that build-in
		// methods like `toString` or __proto__ are not overriden by malicious
		// querystrings.
		//
		// In the case if failed decoding, we want to omit the key/value pairs
		// from the result.
		//
		if (key === null || value === null || key in result) {
			continue;
		}
		result[key] = value;
	}

	return result;
}
